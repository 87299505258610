import React from "react";
import { FaInfoCircle, FaLock, FaUser, FaBan, FaCopyright, FaExternalLinkAlt, FaShieldAlt, FaExclamationTriangle, FaGavel, FaEnvelope } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
export const Terms = () => {

   React.useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    return (
        <div className="p-6  mt-32 max-w-3xl mx-auto bg-white rounded-lg shadow-md">
            <h1 data-aos="fade-up" className="text-3xl font-bold text-gray-800 mb-6">
                <FaInfoCircle className="inline mr-2 text-blue-600" />
                Terms and Conditions
            </h1>

            <section data-aos="fade-up" className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-3">
                    <FaLock className="inline mr-2 text-blue-600" />
                    License
                </h2>
                <p className="text-gray-600">
                    The Company grants you a limited, non-exclusive, non-transferable, revocable license to use the App solely for your personal, non-commercial purposes.
                </p>
            </section>

            <section data-aos="fade-up" className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-3">
                    <FaUser className="inline mr-2 text-blue-600" />
                    User Account
                </h2>
                <p className="text-gray-600">
                    In order to use certain features of the App, you may be required to create a user account. You agree to provide accurate and complete information when creating your account and to keep your login credentials secure. You are solely responsible for all activities that occur under your account.
                </p>
            </section>

            <section data-aos="fade-up" className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-3">
                    <FaBan className="inline mr-2 text-blue-600" />
                    Use Restrictions
                </h2>
                <ul className="list-disc pl-5 text-gray-600">
                    <li>Use the App for any unlawful purpose or in any way that violates these Terms.</li>
                    <li>Modify, adapt, translate, reverse engineer, decompile, or disassemble the App.</li>
                    <li>Use any automated means or interface not provided by the Company to access the App or to extract data.</li>
                    <li>Interfere with or disrupt the operation of the App or servers or networks connected to the App.</li>
                    <li>Attempt to gain unauthorized access to the App or any other systems or networks connected to the App.</li>
                </ul>
            </section>

            <section data-aos="fade-up" className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-3">
                    <FaCopyright className="inline mr-2 text-blue-600" />
                    Intellectual Property
                </h2>
                <p className="text-gray-600">
                    All rights, title, and interest in and to the App, including all intellectual property rights, are owned or licensed by the Company. You acknowledge that you do not acquire any ownership rights by using the App.
                </p>
            </section>

            <section data-aos="fade-up" className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-3">
                    <FaExternalLinkAlt className="inline mr-2 text-blue-600" />
                    Third-Party Content
                </h2>
                <p className="text-gray-600">
                    The App may include content provided by third parties. The Company does not control or endorse any third-party content and assumes no responsibility for the accuracy, reliability, or completeness of such content.
                </p>
            </section>

            <section data-aos="fade-up" className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-3">
                    <FaShieldAlt className="inline mr-2 text-blue-600" />
                    Privacy
                </h2>
                <p className="text-gray-600">
                    Your use of the App is subject to our Privacy Policy, which is incorporated by reference into these Terms. By using the App, you consent to the collection and use of your information as described in the Privacy Policy.
                </p>
            </section>

            <section  data-aos="fade-up" className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-3">
                    <FaExclamationTriangle className="inline mr-2 text-blue-600" />
                    Updates
                </h2>
                <p className="text-gray-600">
                    The Company may from time to time provide updates to the App. These updates may be necessary to improve performance, add new features, or address security concerns. By using the App, you agree to receive such updates automatically.
                </p>
            </section>

            <section data-aos="fade-up" className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-3">
                    <FaGavel className="inline mr-2 text-blue-600" />
                    Termination
                </h2>
                <p className="text-gray-600">
                    The Company reserves the right to terminate or suspend your access to the App at any time, with or without cause and without prior notice.
                </p>
            </section>

            <section data-aos="fade-up" className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-3">
                    <FaExclamationTriangle className="inline mr-2 text-blue-600" />
                    Disclaimer of Warranties
                </h2>
                <p className="text-gray-600">
                    THE APP IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY LAW, THE COMPANY DISCLAIMS ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                </p>
            </section>

            <section data-aos="fade-up" className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-3">
                    <FaGavel className="inline mr-2 text-blue-600" />
                    Law
                </h2>
                <p className="text-gray-600">
                    <strong>Limitation of Liability:</strong> TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE COMPANY SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF YOUR ACCESS TO OR USE OF THE APP, REGARDLESS OF THE CAUSE OF ACTION OR THE THEORY OF LIABILITY.
                </p>
                <p className="text-gray-600">
                    <strong>Governing Law:</strong> These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.
                </p>
                <p className="text-gray-600">
                    <strong>Changes to Terms:</strong> The Company reserves the right to modify or update these Terms at any time without prior notice. Your continued use of the App after any such changes constitutes your acceptance of the new Terms.
                </p>
            </section>

            <section data-aos="fade-up" className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-3">
                    <FaEnvelope className="inline mr-2 text-blue-600" />
                    Contact Us
                </h2>
                <p className="text-gray-600">
                    If you have any questions or concerns about these Terms, please contact us at [Softtellex@gmail.com].
                </p>
            </section>
            <p className="text-gray-600 mt-6">
                By using the App, you acknowledge that you have read, understood, and agree to be bound by these Terms.
            </p>
        </div>
    );
};
