import { Card } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaSpinner } from "react-icons/fa";
import { FaNairaSign } from "react-icons/fa6";
import { useAlert } from "./alert/useAlert";
import { useNavigate } from "react-router-dom";

const BenefitOverlay = ({ navigation, route }) => {
   const showMessage = useAlert(); // Using the custom hook
   const [data, setData] = useState(null);
   const [loadingDetails, setLoadingDetails] = useState(true);
   const [loadingGift, setLoadingGift] = useState(true);
   const [gift, setGift] = useState([]);
   const [amount, setAmount] = useState("");
   const [buttonLoading, setButtonLoading] = useState(false);
   const navigate = useNavigate()


   const token = localStorage.getItem("token");
   const username = localStorage.getItem("user");

   const convertPoint = async () => {
      try {
         setButtonLoading(true);
         const response = await fetch("https://www.softnixx.com/api/convert", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
               Authorization: `Token ${token}`,
            },
            body: JSON.stringify({
               point: parseFloat(amount.trim()),
               id: data?.id
            })
         });
         
         const result = await response.json();
         if (!response.ok) {
            showMessage(result.error, "error");
            return;
         }
         showMessage(result.success, "success");
      } catch (error) {
         
         showMessage("An error occurred", "error");
      } finally {
         setButtonLoading(false);
      }
   };


   const handleGiftClaim = async (id, amount) => {
      try {
         setButtonLoading(true);
         const response = await fetch("https://www.softnixx.com/api/get_gift/", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
               Authorization: `Token ${token}`,
            },
            body: JSON.stringify({
               amount: amount,
               id: id,
               user_id: data?.id
            })
         });

         const result = await response.json();
         if (!response.ok) {
            showMessage("Something went wrong", "error");
         } else {
            showMessage(result.message, "success");
         }
      } catch (error) {
        
         showMessage("An error occurred", "error");
      } finally {
         setButtonLoading(false);
      }
   };

   const getDetails = async () => {
      if (!token || !username) {
         showMessage("Something is missing...", "error");
         return;
      }
      setLoadingDetails(true);
      const itemUrL = `https://www.softnixx.com/api/updateD/${username}/`;
      try {
         const response = await fetch(itemUrL, {
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
               Authorization: `Token ${token}`,
            },
         });
         if (!response.ok) {
            throw new Error("Network response was not ok.");
         }
         const json = await response.json();
         setData(json);
      } catch (error) {
         showMessage(`Error: ${error.message}`, "error");
      } finally {
         setLoadingDetails(false);
      }
   };

   const getGift = async () => {
      setLoadingGift(true);
      const itemUrL = `https://www.softnixx.com/api/giftall`;
      try {
         const response = await fetch(itemUrL, {
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
               Authorization: `Token ${token}`,
            },
         });
         const json = await response.json();
         setGift(json);
      } catch (error) {
         showMessage(`Error: ${error.message}`, "error");
      } finally {
         setLoadingGift(false);
      }
   };

   useEffect(() => {
      getDetails();
      getGift();
   }, []);

   return (
      <div className="pb-4 mb-28 bg-gray-100 flex-1">
          <FaArrowLeft className="m-4" onClick={()=>navigate("/home")} />
         <div className=" text-center p-1 m-2 shadow-md bg-red-300 text-white rounded-lg">
           <p style={{
            fontSize:11,
           }} className="text-black">User with 10 poin above can convert</p>
         </div>
         <div>
            <div className="flex flex-row bg-white shadow-md">
               <div className="text-sm p-4 rounded-lg m-3 bg-gray-100 w-fit">
                  <h3>{data?.profile?.point_bonus || 0} Point</h3>
               </div>
               <div className="text-sm p-4 rounded-lg m-3 bg-gray-100 w-fit">
                  <h3>Balance: {data?.profile?.account_balance?.toLocaleString() || "0"}</h3>
               </div>
            </div>

            {(loadingDetails || loadingGift) ? (
               <center>
               <FaSpinner className="animate-spin text-2xl" />
               </center>
            ) : (
               <div>
                  <div className="flex flex-col">
   
                     <input
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="border-spacing-1 bg-slate-50 p-4 m-3 text-sm border-2 rounded-md"
                        placeholder="How many points do you want to convert? (10 points or more)"
                     />
                  </div>
                  {buttonLoading ? (
                        <FaSpinner className="animate-spin" />
                     ) : (
                        <center>
                        <button onClick={convertPoint} className="text-sm text-white w-1/2 bg-blue-700 p-2  rounded-lg">
                        Submit
                     </button>
                     </center>
                     )}
               </div>
            )}
         </div>

         <div>
            <h3 className="text-gray-600 text-left p-2 text-sm">Gifts available to claim</h3>
            <div className="flex flex-row overflow-x-scroll items-center justify-between">
               {gift.map((item, index) => (
                  <div key={index} className="bg-white shadow-lg rounded-lg p-3 m-3">
                     <h4 className="text-sm text-black p-2 m-2 text-center">
                        {item.product}
                     </h4>
                     <p className="text-sm text-green-600 text-center">{item.user_consume.length} Claimed</p>
                     <h3 className="text-center text-sm">
                         NGN {item.price.toLocaleString()}
                     </h3>
                     <p className="text-sm text-center text-gray-700">Items left: {item.neede_uer}</p>
                     {buttonLoading ? (
                        <FaSpinner className="animate-spin" />
                     ) : (
                        <center>
                        <button
                           onClick={() => handleGiftClaim(item.id, item.price)}
                           disabled={item.neede_uer === 0}
                           className={`${item.neede_uer===0?"bg-gray-300 text-gray-600 mt-2 rounded-lg p-2":"bg-gray-950 text-white p-2 rounded-lg mt-2"}`}
                        >
                           {item.neede_uer === 0 ? "Finished" : "Claim now"}
                        </button>
                        </center>
                     )}
                  </div>
               ))}
            </div>
            <div className="text-sm text-gray-300 text-center">
               Provided by softnixx.
            </div>
         </div>
      </div>
   );
};

export default BenefitOverlay;
