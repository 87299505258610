import React, { useState, useEffect } from 'react';
import { useAlert } from '../alert/useAlert';
import { FaSpinner } from 'react-icons/fa';
import { FaDrawPolygon, FaNairaSign } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Viewall = () => {
  const [barChartData, setBarChartData] = useState([]);
  const [amount3, setAmount3] = useState(null);
  const [loading, setLoading] = useState([])
  const location = useLocation()
  const navigate = useNavigate()

  const showMessage = useAlert()
  const token = localStorage.getItem("token");
  const fetchChartData = async (url) => {
    setLoading(true);
    try {
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${token}`,
        },
      });
      const json = await response.json();
      return json;
    } catch (error) {
      showMessage('Something went wrong', 'error');

      return [];
    } finally {
      setLoading(false);
    }
  };


  const FetchDATA = async () => {
    const url = `https://www.softnixx.com/api/allreceipt`;
    const json = await fetchChartData(url);
    setBarChartData(json)
    setAmount3(json.reduce((acc, item) => acc + item.amount, 0));
  };

  useEffect(() => {
    FetchDATA()
  }, [])

  const headers = ["Type", "Amount", "Status", "Ref"];
  return (
    <div>
      {
        loading ?
          (<div>
            <center>
              <FaSpinner className="animate-spin" />
              <p>Caculation in progress...</p>
            </center>
          </div>
          ) :

          (

            <div>
              <div className='bg-white p-3 w-full m-2 flex flex-row items-center rounded-lg'>
                Total Transactions
                <FaNairaSign className='ml-2 ' />
                <h3> {amount3?.toLocaleString()}
                </h3>

                <h3 className='ml-2'> Total Transactions in numbers {barChartData?.length.toLocaleString()}</h3>
              </div>
              <p className='p-2 m-1 text-sm text-gray-500'>This is the Overall transaction made on softnixx since launch, this may include the refund policy also.</p>

              <h4 className='text-left p-2 m-2 text-gray-600'>Break Down</h4>

              <table className="min-w-full table-auto">
                <thead className="bg-gray-100">
                  <tr>
                    {headers.map((header, index) => (
                      <th
                        key={index}
                        className="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody >

                  {barChartData?.map((item, index) => (
                    <tr onClick={()=>navigate("/admin-dashboard/details", {state:item?.id})} key={index} className="border-none">
                      <td className="p-2 m-1 text-sm font-light">{item.name === "transfer" ? "deposited" : (item.name === "gift" ? "data purchased" : item?.name)}</td>
                      <td className="p-2 m-1 text-sm font-light">N{item.amount}</td>
                      <td className="p-2 m-1 text-sm font-light">{item.type}</td>
                      <td className="p-2 m-1 text-sm font-light">{item.reference}</td>

                    </tr>
                  ))}
                </tbody>

              </table>






            </div>


          )
      }</div>
  )
}



export default Viewall;