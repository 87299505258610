import React, { useState } from "react";
import Logo from '../../src/show.png';
import { FaAlipay, FaBars, FaHome, FaRubleSign, FaSign, FaSignInAlt } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { FaPeopleLine } from "react-icons/fa6";
import AOS from "aos";
import "aos/dist/aos.css";
import { useAlert } from "./alert/useAlert";


export const Header = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const showMessage = useAlert()
    const toggleMenu = () => setMenuOpen(prev => !prev);

    const handleNavigation = (to) => {
        navigate(to);
        setMenuOpen(false);
    };

    return (
        <header  className="flex flex-row items-center fixed top-0 right-0 left-0 py-4 px-4 md:px-10 shadow-lg bg-white z-50">
            <div  className="flex items-center w-full md:w-auto">
                <img className="w-[8rem] md:w-[10rem]" src={Logo} alt="Logo" />
            </div>
            <button
                onClick={toggleMenu}
                className="md:hidden flex items-center ml-auto"
                aria-label="Toggle menu"
            >
                <FaBars className="text-gray-700" />
            </button>
            <div data-aos-delay="200" className={`md:flex md:items-center md:w-auto w-1/2 h-full  z-10 ${menuOpen ? 'block fixed top-0 left-0 bg-white shadow-lg  p-4' : 'hidden'}`}>
                <button
                    onClick={toggleMenu}
                    className="absolute top-4 right-4 md:hidden flex items-center"
                    aria-label="Close menu"
                >
                    <CgClose className="text-gray-700" />
                </button>

                <nav data-aos="fade-up" data-aos-delay="200" className="w-full md:w-auto">
                    <ul className="flex flex-col md:flex-row items-start md:items-center text-base xl:text-lg text-gray-950 pt-4 md:pt-0">
                        <li className="flex flex-row items-center w-full shadow-sm m-1"><FaHome className="text-blue-500 m-2 " /> <a className="md:p-4 py-3 px-0 block text-blue-950 text-sm hover:text-blue-700" href="/">Home</a></li>
                        <li  className="flex flex-row items-center w-full shadow-sm m-1"><FaPeopleLine className="text-blue-500 m-2 "  /> <button className="md:p-4 py-3 px-0 block text-sm hover:text-blue-700" onClick={() => handleNavigation("/developer")}>Developer</button></li>
                        <li  className="flex flex-row items-center w-full shadow-sm m-1"><FaRubleSign className="text-blue-500 m-2 " /> <button className="md:p-4 py-3 px-0 block text-sm hover:text-blue-700" onClick={() => handleNavigation("/terms")}>Terms and Conditions</button></li>
                        <li  className="flex flex-row items-center w-full shadow-sm m-1"><FaSign  className="text-blue-500 m-2 " /> <button className="md:p-4 py-3 px-0 block text-sm hover:text-blue-700" onClick={() => handleNavigation("/about")}>About Softnixx</button></li>
                        <li  className="flex flex-row items-center w-full shadow-sm m-1"><FaAlipay className="text-blue-500 m-2 "  /><button className="md:p-4 py-3 px-0 block text-sm hover:text-blue-700" onClick={() => handleNavigation("/project")}>Projects/Api</button></li>
                        <li  className="flex flex-row items-center w-full shadow-sm m-1"><FaSignInAlt className="text-blue-500 m-2 " /><button className="md:p-4 py-3 px-0 block text-sm hover:text-blue-700 md:mb-0 mb-2" onClick={()=>showMessage("downlaod the mobile app on google play.. web version has been depreciated", "info")}>Login</button></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};
