import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom";

export default function Details() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const location = useLocation()





  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://www.softnixx.com/api/receiptC/${location.state}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const jsonData = await response.json();
      setData(jsonData);
      setLoading(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-6 bg-gray-100 min-h-screen flex flex-col items-center">
      {loading && <p className="text-lg text-blue-600">Loading...</p>}
      {error && <p className="text-lg text-red-500">Error: {error}</p>}
      {data && (
        <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6">
          <center> <h1 className="text-gray-950 p-1 m-1 text-2xl ">
                   Amount {data?.amount.toLocaleString()}
                    </h1></center>
         
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Details</h1>
          <table className="table-auto w-full border-collapse border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2 text-left">Field</th>
                <th className="border border-gray-300 px-4 py-2 text-left">Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Name</td>
                <td className="border border-gray-300 px-4 py-2">{data.name}</td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Reference</td>
                <td className="border border-gray-300 px-4 py-2">{data.reference}</td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Request ID</td>
                <td className="border border-gray-300 px-4 py-2">{data?.request_id}</td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Status</td>
                <td className="border border-gray-300 px-4 py-2">
                  {data?.status && (
                    <FaCheckCircle className="text-green-500 text-xl" />
                  )}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Username</td>
                <td className="border border-gray-300 px-4 py-2">
                  {data.user.username}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Account Balance</td>
                <td className="border border-gray-300 px-4 py-2">
                  NGN {data?.user.profile.account_balance.toLocaleString()}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Phone Number</td>
                
                <td className="border border-gray-300 px-4 py-2">
                  {data?.user.profile.phone_number}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
