import React, { useState, useEffect } from "react";
import "../home.css";
import { Allserv } from "./renderAllservices";
import { Link } from "react-router-dom";
import ModalComponent from "./modal";
import { Scollling } from "./scrollservices";
import { FaAngleDown, FaBars, FaEyeSlash,  FaHeadset, FaVolumeHigh } from "react-icons/fa6";
import {
  FaAngleRight,
  FaBell,
  FaEye,
  FaGooglePlay,
  FaHistory,
  FaSignOutAlt,
  FaUsers,
} from "react-icons/fa";
import { FaSpinner } from "react-icons/fa";
import { useAlert } from "../../component/alert/useAlert";
import { FaPlus } from "react-icons/fa"; // Import the icon from react-icons
import { useNavigate } from "react-router-dom";
import Logo from "../../../src/comp.jpg"

import ReferModal from "../../component/modalLarge";
import { CgClose } from "react-icons/cg";

export const Service = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null); // Initialize as null instead of empty array
  const showMessage = useAlert();
  const [open, setOpen] = useState(false);
  const [showTransactions, setShowTransactions] = useState(true);
  const [eye, setEye] = useState(false)
  const [refer, setRefer] = useState(true)




  const phoneNumber = '+2349061229992'; // Replace with the actual phone number in international format
  const message = 'Hello, I would like to inquire about your services.';

  // WhatsApp URL
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;



  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(`https://www.softnixx.com/register?referer=${data?.profile?.tract_id}`);
      showMessage("successfully copy referer link")
    } catch (err) {
     showMessage("somethimng went wrong")
    }
  };

  const navigate = useNavigate();
  const ToggleOpen = () => {
    setOpen(!open);
  };

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const token = localStorage.getItem("token");
  const username = localStorage.getItem("user");

  const getDetails = async () => {
    if (!token || !username) {
      showMessage("Something is missing...", "error");
      return;
    }
    setLoading(true);

    const itemUrL = `https://www.softnixx.com/api/updateD/${username}/`;
    try {
      const response = await fetch(itemUrL, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const json = await response.json();

      if (json.profile.paystack_id === null || json.profile.paystack_id === "") {
        const customerUrl = 'https://api.paystack.co/customer';
        const customerData = {
          email: json.email,
          first_name: json.last_name,
          last_name: json.first_name,
          phone: json.phone,
        };
        const authorization = 'Bearer sk_live_ea796eb8e2daee044b8143ccc52c8c5f155da9d2';
        const customerResponse = await fetch(customerUrl, {
          method: 'POST',
          headers: {
            'Authorization': authorization,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(customerData),
        });
        if (!customerResponse.ok) {
          throw new Error(`Error creating Paystack customer: ${customerResponse.statusText}`);
        }
        const customerResult = await customerResponse.json();
        const createNewUrl = 'https://www.softnixx.com/api/create_new';
        const createNewResponse = await fetch(createNewUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify({
            email: json.email,
            customer_id: customerResult.data.customer_code,
          }),
        });
        if (!createNewResponse.ok) {
          throw new Error(`Error sending data to server: ${createNewResponse.statusText}`);
        }
      }
      setData(json);
    } catch (error) {
      showMessage(`Error: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetails();
  }, []);

  const formatLocaleString = (value) => {
    if (value != null) {
      return value.toLocaleString();
    }
    return "N/A";
  };

  const Logout = () => {
    localStorage.clear();
    showMessage("Logout succesfully..!", "error");
    navigate("/login");
  };


  const toggleTransactions = () => {
    setShowTransactions(!showTransactions);
  };
  const openEYE = () => {
    setEye(!eye)
  }

  function capitalizeFirstLetter(string) {
    if (string) {
      return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    }
    return <FaSpinner className="animate-spin" />

  }
  return (
    <>
      {open && (
       
          <div
            className={`fixed top-0 left-0 z-10 h-screen w-2/3 lg:w-64 bg-white text-gray-950 p-4 flex flex-col transition-transform duration-300 ${open ? "translate-x-0" : "-translate-x-full"
              } lg:translate-x-0`}
          >
            <CgClose onClick={()=>setOpen(!open)} className="text-red-400 shadow-lg text-xl m-1 rounded-lg" />
            <div className="flex items-center mb-6">
              <img
                src={Logo}
                alt="User Avatar"
                className="w-5 h-5 rounded-full mr-3 border-2 border-gray-500"
              />
              <div>
                <h2 className="text-sm font-semibold">{data?.username?.toUpperCase()}</h2>
                <p style={{
                  fontSize: 10
                }} className="text-gray-400">{data?.email}</p>
              </div>
            </div>
            <h2 className="text-sm font-bold mb-6">Personal board</h2>
            <input
              type="text"
              placeholder="Search..."
              className="mb-4 w-full px-3 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
            />
            <ul className="flex-1 space-y-3">

              <div>
                {/* Tract Transaction header */}

                <div className="p-1 m-1 rounded-lg bg-blue-950">
                  <center>
                    <button onClick={() => navigate("benefit")} className="text-white text-sm text-center"  >
                      Claim Gift and Point
                    </button>
                  </center>
                </div>

                <div className="flex flex-row items-center justify-between p-1 m-1 ">
                  <h3
                    onClick={toggleTransactions}
                    className="cursor-pointer font-bold text-gray-500 text-sm "
                  >
                    Tract Transaction
                  </h3>
                  {!showTransactions ?
                    <FaAngleRight color="gray" size={15} />
                    : <FaAngleDown color="gray" size={15} />}
                </div>
                {showTransactions && (
                  <div className="ml-1 mt-2">
                    <button
                      onClick={() => navigate("trans")}
                      className="p-2 hover:underline cursor-pointer">•Deposit Transactions</button>
                    <button
                      onClick={() => navigate("trans")}
                      className="p-2 hover:underline cursor-pointer">•Airtime and Data Transactions</button>
                    <button className="p-2 hover:underline cursor-pointer">•Electricity Transactions</button>
                    <button className="p-2 hover:underline cursor-pointer">•Exam E-pins</button>
                  </div>
                )}
              </div>
              <div className="items-center flex justify-around">
                <button onClick={handleCopy}>

                  Refer a friend
                </button>
                <FaUsers />
              </div>
              <li>
                <Link onClick={Logout} to="/login" className="flex items-center p-3 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">
                  <FaSignOutAlt className="mr-2" /> Logout
                </Link>
              </li>
              <li>
                <Link onClick={Logout} to="/delete" className="flex items-center p-3 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">
                  <FaSignOutAlt className="mr-2" /> Delete Account
                </Link>
              </li>
            </ul>
          </div>
      
      )}






      {/* start here */}


      <div className="container lg:ml-0 ml-0 p-1 bg-gray-100 w-auto text-darkblue shadow-lg rounded-lg">

        <div className="flex flex-row justify-between align-middle content-center">
          <button
            onClick={ToggleOpen}
            className={`flex items-center p-2 rounded-full ${open ? "text-black" : "text-white"
              }`}
          >
            <FaBars
              className={`text-xl ${open
                  ? "bg-white shadow-lg text-white right-0"
                  : "text-gray-950 bg-white shadow-lg"
                }`}
            />
          </button>
          <div className="flex flex-row items-center">
            <FaHeadset className="mr-3" />
            <div>
              <h6 className="text-red-500 text-2xl font-bold rounded-lg shadow-sm absolute top-0 right-2">•</h6>
              <FaBell className="text-gray-500-500" />
            </div>
          </div>

        </div>




        <div className="bg-white p-2 pl-5 pr-5 m-3 flex flex-row items-center rounded-lg shadow-lg  ">
          <FaVolumeHigh className="text-2xl text-blue-700" /> <marquee className="text-sm pl-2 text-red-800">Important Announcement: Some features have not being activated, due to slow upgrade.
          </marquee>
        </div>



        <div className="shadow-lg p-3 m-3 " >
          <div>


<div className="bg-blue-300 p-2 m-1 rounded-lg">
            <div className="flex flex-row items-center">
              <h4>Wallet Balance</h4>
              {eye ? <FaEyeSlash onClick={openEYE} className=" ml-6 " /> : <FaEye onClick={openEYE} className=" ml-6 " />}
            </div>

            <div className="flex flex-row items-center justify-between">
            <div className="">
              {loading ? <h4>0.00</h4>
                : <h2>NGN {eye ? formatLocaleString(data?.profile?.account_balance) : "---"}   </h2>
              }
            </div>

            <div className="bg-gray-500 rounded-lg ">
          <center>
            <h4 className=" text-white w-fit flex flex-row items-center p-2 rounded-lg">
              <FaPlus className="text-sm text-white mr-3" />
              <button onClick={handleOpen} className="text-sm  text-white">
                Deposit
              </button>
            </h4>
          </center>
        </div>

        </div>
</div>
            <Scollling />
          </div>

        </div>
      
        <div>
            <p style={{
              fontSize:13,
            }} className="text-gray-600 p-2 m-1">Quick Actions</p>
            <Allserv />
          </div>
<div className="flex flex-row items-center justify-around">
  <div className="bg-white shadow-lg rounded-lg p-2 m-2">
    <center>
      <a href="https://play.google.com/store/apps/details?id=com.yourdullboy.softnixx&hl=en-US&ah=_4FiPLfvITOnI4jNuhXixjjVObQ">
      <FaGooglePlay  className="text-xl text-gray-500 " />
      </a>

  <p style={{
    fontSize:10,
    color:"gray"
  }}>Mobile App</p></center>
  </div>
<div className="bg-white shadow-lg rounded-lg p-2 m-2">
  <center>
  <FaHeadset  onClick={() => window.open(whatsappUrl, '_blank')} className="text-xl text-gray-500 " />
  <p style={{
    fontSize:10,
    color:"gray"
  }}>Help</p></center>
</div>

<div className="bg-white shadow-lg rounded-lg p-2 m-2">
  <center>
  <FaHistory onClick={()=>showMessage("Options not yet activated")} className="text-xl text-gray-500 " />
  <p style={{
    fontSize:10,
    color:"gray"
  }}>History</p></center>
</div>


<div className="bg-white shadow-lg rounded-lg p-2 m-2">
  <center>
  <FaUsers onClick={handleCopy} className="text-xl text-gray-500 " />
  <p style={{
    fontSize:10,
    color:"gray"
  }}>Refer</p></center>
</div>
</div>

<div className="text-sm mb-16 bg-gray-500 text-white p-3  rounded-lg text-center">
      <p>
         December Promo !!!!
            Be a top user and share N50K
      </p>
        </div>  


        <ReferModal
          close={() => setRefer(false)}
          user={data?.username}
          copy={handleCopy} open={refer} />

      </div>
      <ModalComponent open={modalOpen} onClose={handleClose} />

    </>
  );
};
