import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../alert/useAlert";

export default function LoginAdmin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const showMessage = useAlert();

  async function Login() {
    try {
      setLoading(true);
      const url = "https://www.softnixx.com/api/admin";

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();      
      if (response.ok) {
        navigate("/admin-dashboard"); 
        localStorage.setItem("key",data.data.key)
        localStorage.setItem("staff",data.staff)
      } else {
        showMessage("Login failed: " + (data.message || "Invalid credentials"), "error");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      showMessage("An error occurred during login. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
        padding: "1rem",
      }}
      className="h-screen"
    >
      <div
        style={{
          width: "100%",
          maxWidth: "400px",
          backgroundColor: "#ffffff",
          padding: "2rem",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h2 style={{ marginBottom: "1rem", fontSize: "1.5rem" }}>Admin Login</h2>
        </div>

        <div>
          <label style={{ display: "block", marginBottom: "0.5rem" }} htmlFor="email">
            Username
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              width: "100%",
              padding: "0.75rem",
              borderRadius: "4px",
              border: "1px solid #ccc",
              outline: "none",
            }}
            placeholder="Enter your email"
            aria-label="Email address"
          />
        </div>

        <div>
          <label style={{ display: "block", marginBottom: "0.5rem" }} htmlFor="password">
            Password
          </label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              width: "100%",
              padding: "0.75rem",
              borderRadius: "4px",
              border: "1px solid #ccc",
              outline: "none",
            }}
            placeholder="Enter your password"
            aria-label="Password"
          />
        </div>

        <button
          onClick={Login}
          disabled={loading}
          style={{
            padding: "0.75rem",
            width: "100%",
            backgroundColor: loading ? "#b0c4de" : "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: loading ? "not-allowed" : "pointer",
            fontSize: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          aria-label="Login"
        >
          {loading ? <FaSpinner className="animate-spin" /> : "Login"}
        </button>
      </div>
    </div>
  );
}
