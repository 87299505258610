import React from "react";

import { useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";
import { useAlert } from "../../component/alert/useAlert";
import { CircularProgress } from "@mui/material";

import { FaPhoneAlt, FaWifi } from "react-icons/fa";


export const Scollling = () => {
  const navigate = useNavigate();
  const showMessage = useAlert();
const [loading, setLoading] = useState(false);
const [data, setData] = useState([]);

const fetchNotifications = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const url = "https://www.softnixx.com/api/generate_receipt/";
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Token ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
    } catch (error) {
        showMessage("Something went wrong!...", "error");
    } finally {
        setLoading(false);
    }
};

useEffect(() => {
    fetchNotifications();
}, []);

const Timeset = (data) => {
    const date = new Date(data);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};


  return (
    <>

<center>
        <div>
            <div className="flex flex-row justify-between items-center">
            <h5 style ={{
                fontSize:11,
            }} className="text-gray-600 font-mono">Random Activities </h5>
            <button style={{
                fontSize:12
            }} className=" p-2 text-blue-700 rounded-lg" onClick={()=>navigate("notification")}>
              View All
            </button>

            </div>
          
            {loading ? (
                <div className="flex justify-center items-center">
                    <CircularProgress />
                </div>
            ) : data.length === 0 ? (
                <div className="flex flex-col items-center space-y-4">
                    <p  className="text-red-300 text-sm ">You have no notifications at the moment.</p>
                </div>
            ) : (
            <div className="p-1 m-1">
             {data.splice(0,3).map((item,index)=>(
              <div className={`${index%2 ===0?"bg-white":"bg-gray-100"} rounded-sm p-1 m-1 flex flex-row items-center justify-between`}>

<div>
<h4> 
{item.name==="gift"? <FaWifi color="blue"  size={12} className="text-left w-fit bg-gray-100 rounded-lg" /> : 
  <FaPhoneAlt color="blue"  size={12} className="text-left  w-fit bg-gray-100 rounded-lg" />  
             }
</h4>
<h6 style={
    {
        fontSize:10
    }
  } className="text-left text-gray-400">{Timeset(item.createdAt)}</h6>
</div>       

<div>
<div>
  <h3 style={{
                    fontSize:12,
                 }} >

  {item?.name ==="gift"?(item?.name==="gift"?"Data":"Airtime"):(item?.name)}
  </h3>
 
  </div>
  <div>
                 <h6 style={{
                    fontSize:10,
                 }} className="  text-gray-400">NGN {item.amount.toLocaleString()}</h6> 
</div>
</div>
</div>
             ))}
              </div>
            )}
         
        </div>

        <div>

            </div>
    
        </center>
    </>
  );
};


