import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useAlert } from '../../../alert/useAlert';

export const UserDetail = () => {
    const showMessage = useAlert()
    const location = useLocation();
    const username = location.state || {}; // Destructure username from location.state

    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [message, setMessage] = useState("");

    // State for form inputs
    const [formData, setFormData] = useState({
        email: '',
        date_joined: '',
        first_name: '',
        last_name: '',
        last_login: '',
        account_balance: '',
        phone_number: '',
        tract_id: '',
    });
    useEffect(() => {
        const getDetails = async () => {
            if (!token || !username) {
                alert('Something is missing..');
                return;
            }
            setLoading(true);
            const itemUrl = `https://www.softnixx.com/api/updateD/${username}/`;
            try {
                const response = await fetch(itemUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Token ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const json = await response.json();
                setData(json);

                setFormData({
                    email: json.email,
                    date_joined: json.date_joined,
                    first_name: json.first_name,
                    last_name: json.last_name,
                    last_login: json.last_login,
                    account_balance: json.profile?.account_balance || '',
                    phone_number: json.profile?.phone_number || '',
                    tract_id: json.profile?.tract_id || '',
                });
            } catch (error) {
                showMessage("seomthing went wrong try again later","error")
            } finally {
                setLoading(false);
            }
        };

        getDetails();
    }, [token, username]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSubmit = async () => {
        // Validate data if necessary
        if (!formData.account_balance || !message) {
            alert('Please fill in all required fields');
            return;
        }

        setLoading(true);
        const itemUrl = `https://www.softnixx.com/api/plus`;
       
            const response = await fetch(itemUrl, {
                method: "POST",
                body: JSON.stringify({
                    amount: formData.account_balance,
                    letter: message,
                    user_id: data.id
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Token ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const json = await response.json();
           
            setData(json);
            alert('User balance updated successfully');
            setLoading(false);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-40">
                <CircularProgress />
            </div>
        );
    }

    if (!data) {
        return (
            <div className="text-center text-gray-500 p-6">
                <p>No user data found</p>
            </div>
        );
    }

    return (
        <div className="p-6 max-w-3xl mx-auto bg-white border border-gray-300 rounded-lg shadow-md">
            <h1 className="text-3xl font-bold mb-6 text-gray-900">{username}</h1>
            <div className="space-y-6">
                <UserDetailRow
                    label="Email:"
                    value={
                        <input
                            type="text"
                            name="email"
                            disabled
                            value={formData.email}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded-lg p-2 w-full"
                        />
                    }
                />
                <UserDetailRow
                    label="Date Joined:"
                    value={
                        <input
                            type="text"
                            name="date_joined"
                            disabled
                            value={formData.date_joined}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded-lg p-2 w-full"
                        />
                    }
                />
                <UserDetailRow
                    label="First Name:"
                    value={
                        <input
                            type="text"
                            name="first_name"
                            disabled
                            value={formData.first_name}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded-lg p-2 w-full"
                        />
                    }
                />
                <UserDetailRow
                    label="Last Name:"
                    value={
                        <input
                            type="text"
                            name="last_name"
                            disabled
                            value={formData.last_name}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded-lg p-2 w-full"
                        />
                    }
                />
                <UserDetailRow
                    label="Last Login:"
                    value={
                        <input
                            type="text"
                            name="last_login"
                            value={formData.last_login}
                            disabled
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded-lg p-2 w-full"
                        />
                    }
                />
                <h4 className="text-xl font-semibold mt-6 mb-4 text-gray-700">Profile</h4>
                <UserDetailRow
                    label="Account Balance:"
                    value={
                        <input
                            type="text"
                            name="account_balance"
                            value={formData.account_balance}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded-lg p-2 w-full"
                        />
                    }
                />
                <UserDetailRow
                    label="Message:"
                    value={
                        <input
                            type="text"
                            name="message"
                            value={message}
                            onChange={handleMessageChange}
                            className="border border-gray-300 rounded-lg p-2 w-full"
                        />
                    }
                />
                <UserDetailRow
                    label="Phone Number:"
                    disabled
                    value={
                        <input
                            type="text"
                            name="phone_number"
                            disabled
                            value={formData.phone_number}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded-lg p-2 w-full"
                        />
                    }
                />
                <UserDetailRow
                    label="Tract ID:"
                    disabled
                    value={
                        <input
                            type="text"
                            name="tract_id"
                            value={formData.tract_id}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded-lg p-2 w-full"
                        />
                    }
                />
            </div>
            <button
                onClick={handleSubmit}
                className="w-full mt-6 rounded-lg bg-blue-500 p-3 text-white font-semibold hover:bg-blue-600 transition"
            >
                Submit
            </button>
        </div>
    );
};

// A reusable component for displaying user details with inputs
const UserDetailRow = ({ label, value }) => (
    <div className="flex justify-between items-center">
        <span className="font-semibold text-gray-700">{label}</span>
        <div className="flex-1 ml-4">{value}</div>
    </div>
);
