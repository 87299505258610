import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { BsWhatsapp } from "react-icons/bs";
import { FaHandPointDown, FaHandPointLeft, FaGlobe, FaUserCheck } from "react-icons/fa6";

import MyComponentB from "./button";

import Phone from "../../src/1.png";
import phone2 from "../../src/comp.jpg";
import { FaGooglePlay, FaPlayCircle } from "react-icons/fa";



export const Footer = () => {
    const handleOpenWhatsApp = () => {
        window.open("https://wa.me/+2349061229992", "_blank");
    };

    return (
        <div className="bg-blue-950 p-3 md:p-3 text-center text-white">

            <div>
                <p>Prouct of Softtellex technology ltd.</p>
                <p>Technology Company focus on improving life and society standard</p>
            </div>
            <h2 className="text-4xl md:text-5xl font-bold mb-4" data-aos="fade-up">Softnixx 1.0</h2>
            <p className="text-lg mb-4" data-aos="fade-up">Contact Us via the following</p>
            <button onClick={handleOpenWhatsApp} data-aos="zoom-in">
                <BsWhatsapp className="text-6xl md:text-7xl mx-auto mb-4 text-white shadow-lg" />
            </button>
            <div className="mb-6" data-aos="fade-up">
                <h3 className="text-2xl md:text-3xl font-semibold mb-2">Download the App and Enjoy Benefits Now</h3>
                <a
                
                    href="https://play.google.com/store/apps/details?id=com.yourdullboy.softnixx&hl=en-US&ah=_4FiPLfvITOnI4jNuhXixjjVObQ"
                    className="bg-blue-600 z-50 text-white text-lg font-semibold py-2 px-6 rounded-lg shadow hover:bg-blue-700 transition duration-300"
                >
                    Download Now
                </a>
            </div>
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-6 md:gap-12 mt-6">
                <div className="flex flex-col items-center" data-aos="fade-up" data-aos-delay="200">
                    <div className="inline-flex gap-1 items-center mb-2">
                        <h2 className="text-4xl md:text-5xl font-semibold">1</h2>
                        <h2 className="text-4xl md:text-5xl font-semibold">k</h2>
                    </div>
                    <h4 className="text-sm md:text-lg">Daily Transactions</h4>
                </div>

                <div className="flex flex-col items-center" data-aos="fade-up" data-aos-delay="400">
                    <div className="inline-flex gap-1 items-center mb-2">
                        <h2 className="text-4xl md:text-5xl font-semibold">10</h2>
                        <h2 className="text-4xl md:text-5xl font-semibold">+</h2>
                    </div>
                    <h4 className="text-sm md:text-lg">Monthly Giveaways</h4>
                </div>

                <div className="flex flex-col items-center" data-aos="fade-up" data-aos-delay="600">
                    <div className="inline-flex gap-1 items-center mb-2">
                        <h2 className="text-4xl md:text-5xl font-semibold">1K</h2>
                        <h2 className="text-4xl md:text-5xl font-semibold">+</h2>
                    </div>
                    <h4 className="text-sm md:text-lg">Gifts Claimed</h4>
                </div>
            </div>
            

            <div className="flex flex-col bg-blue-900 items-center" data-aos="fade-up" data-aos-delay="600">
                Read more on our blog
            </div>
        </div>
    );
};




export const LandingPage = () => {
    const [openHelp, setOpenHelp] = useState(false);

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    const handleOpenWhatsApp = () => {
        window.open("https://wa.me/+2349061229992", "_blank");
        setOpenHelp(!openHelp);
    };

    const handeOpenHelp = () => {
        setOpenHelp(!openHelp);
    };

    return (
        
            <main className="container lg:ml-32 p-auto bg-white w-auto text-darkblue shadow-lg overflow-hidden rounded-lg mt-32">
                {/* Help Button */}
                <div  className="text-gray p-3 m-2 bg-white shadow-lg top-1/2 right-4 fixed">
                    <h3>
                        <button onClick={!openHelp ? handeOpenHelp : handleOpenWhatsApp}>
                            {!openHelp ? "Need Help?" : "WhatsApp"}
                        </button>
                        {!openHelp ? (
                            <FaUserCheck className="text-gray-500 text-3xl" />
                        ) : (
                            <BsWhatsapp className="text-green-500 text-3xl" />
                        )}
                        <p>Chat with our agent online</p>
                    </h3>
                </div>

                {/* Welcome Section */}
                <p className="shadow-lg rounded-lg bg-white text-center p-3 m-3 text-blue-950 text-3xl" data-aos="fade-right">
                    Welcome to Softnixx Platform, Home of utilities
                     <b>  Airtime, Data, Electricity, etc..</b> management.
                </p>

                <div className="p-3 m-9 rounded-lg shadow-lg" data-aos="fade-up">
  <p>
    Our mission is to provide a simpler and more efficient way to purchase your favorite data, airtime, and utilities.
  </p>
  <p>
    Additionally, we offer a seamless solution to convert airtime into cash. It’s quick to register, easy to use, and designed with you in mind. Click the button below to get started today!
  </p>
</div>


                {/* Operations Section */}
                <div className="p-3 m-3 rounded-lg shadow-lg" data-aos="fade-up">
                    <img  src={phone2} width={300} height={300} alt=" softnixx-image2" />
                    <h2 className="text-center text-2xl p-3 m-3">HOW WE OPERATE</h2>
                    <p data-aos="fade-up" className="p-3 m-3 text-sm text-justify text-gray-950">
                        Softnixx functions like a combination of a management platform and a small scale bank, allowing you to
                        manage all your utilities and withdraw money within minutes. Our platform offers a clean
                        user interface for easy interactions and ensures lightning-fast communication with our software for
                        an optimal user experience. Download the app to get started.
                    </p>
                    <center>
                       
                    <a className="text-white p-3 rounded-lg bg-blue-950" href="https://play.google.com/store/apps/details?id=com.yourdullboy.softnixx&hl=en-US&ah=_4FiPLfvITOnI4jNuhXixjjVObQ">
                        Click here to download and get started   
                      </a>  <FaGooglePlay className="text-blue-950 text-3xl m-3" /></center>
                </div>

                {/* Benefits Section */}
                <div className="flex flex-col md:flex-row items-center justify-center w-screen">
                    <div data-aos="fade-right">
                        <img  data-aos="fade-left" src={Phone} width={300} height={300} alt="softnixx-image" className="shadow-lg p-3 m-4 rounded-lg" />
                        <center>
                      <a className="text-white p-3 rounded-lg bg-blue-950" href="https://play.google.com/store/apps/details?id=com.yourdullboy.softnixx&hl=en-US&ah=_4FiPLfvITOnI4jNuhXixjjVObQ">
                        Click here to download
                      </a></center>
                    </div>
                    <div className="shadow-lg p-3 m-4 rounded-lg w-full md:w-1/2" data-aos="fade-left">
                        <h1 className="text-center text-2xl text-blue-950">Get your connections back</h1>
                        <FaGlobe className="animate-spin text-green-400 text-5xl text-center" />
                        <ul className="text-blue-800 text-left text-2xl m-3 p-4">
                            <li className="p-2 m-2 flex flex-row items-center">
                                -- Cheap Data <FaHandPointLeft className="animate-bounce text-2xl ml-2 text-blue-500" />
                            </li>
                            <li className="p-2 m-2 flex flex-row items-center">
                                -- Buy Airtime <FaHandPointLeft className="animate-bounce text-2xl ml-2 text-blue-500" />
                            </li>
                            <li className="p-2 m-2 flex flex-row items-center">
                                -- Redeem Points <FaHandPointLeft className="animate-bounce text-2xl ml-2 text-blue-500" />
                            </li>
                            <li className="p-2 m-2 flex flex-row items-center">
                                -- Pay Electricity Bills{" "}
                                <FaHandPointLeft className="animate-bounce text-2xl ml-2 text-blue-500" />
                            </li>
                            <li className="p-2 m-2 flex flex-row items-center">
                                -- Claim tokens and Gifts{" "}
                                <FaHandPointLeft className="animate-bounce text-2xl ml-2 text-blue-500" />
                            </li>
                        </ul>
                    </div>
                </div>

                <div data-aos="fade-up" data-aos-delay="200" >
                    <h2 className="shadow-sm text-blue-950 text-3xl p-2 m-2 text-center"> Gift And Reward</h2> 
                    <p className="shadow-sm text-blue-900 text-sm p-3 m-2 text-justify"> Join Our Platform and Earned our rewards 
                    <ul>
                        <li>
                            Point reward: Convert your point into your Balance and use it to manage your utlities payment.
                        </li>
                        <li>
                            Claim Gift: Gift are available randomly for all our users to claim, terms and connections applied
                        </li>
                        </ul>

                     </p>
                </div>


                <div data-aos="fade-up" data-aos-delay="200" >
                <h2 className="shadow-sm text-blue-950 text-3xl p-2 m-2 text-center">How It Works 1</h2> <p className="shadow-sm text-blue-900 text-sm p-3 m-2 text-justify"> <ul> <li>-- Fund your wallet </li> <li> -- Pay for your utilities </li> <li>-- Get receipts</li> <li> -- Only successful transactions will be debited for your utilities payment; otherwise, you need to try again.</li> </ul> </p> 
                </div>

                <div data-aos="fade-up" data-aos-delay="200" >
                <h2 className="shadow-sm text-blue-950 text-3xl p-2 m-2 text-center">How It Works 2</h2> 
                <p className="shadow-sm text-blue-900 text-sm p-3 m-2 text-justify"> <ul> <li> -- We have an inbuilt function to help you make sales in your respective market structure, whether medium or small-scale business. </li> 
                <li>-- Click on the plus sign</li>
                 <li> -- Upload your product with the specified guidelines</li> 
                 <li> -- Wait for customers to contact you. </li> </ul>
                 </p>
                        </div>


                <div data-aos="fade-up" data-aos-delay="200">
                <h2 className="shadow-sm text-blue-950 text-3xl p-2 m-2 text-center"> Management of Funds</h2> <p className="shadow-sm text-blue-900 text-sm p-3 m-2 text-justify"> <ul> <li>-- Transfer to other banks (On hold) </li> <li> -- Transfer to Softnixx account </li> <li> -- Deposit money into your account</li>
                <li>-- Refund policy (A failed transactions ('purchased') and your funds will be reversed)</li> 
                 </ul> </p>

                            <center>
                            <div data-aos="fade-up" data-aos-delay="200"  className="bg-blue-950 flex flex-row rounded-lg items-center w-fit p-1 justify-center">
                
                 <FaGooglePlay className="text-white text-3xl" />

                 <a className="text-white  m-4 rounded-lg" href="https://play.google.com/store/apps/details?id=com.yourdullboy.softnixx&hl=en-US&ah=_4FiPLfvITOnI4jNuhXixjjVObQ">
                        Click here to download 
                      </a>
                      </div>
                      </center>

                </div>

            </main>
    
    );
};
