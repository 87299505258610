import React, { useState } from "react";
import { FaEye, FaEyeSlash, FaUser, FaLock } from "react-icons/fa";
import { Button } from "../Button/Button";
import { useAlert } from "../alert/useAlert";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Input } from "./Input";

export const Form = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const showAlert = useAlert();

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("https://www.softnixx.com/api/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          username: username.trim(),
          password: password.trim(),
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setUsername("");
        setPassword("");
        localStorage.setItem("token", data.data.key);
        localStorage.setItem("profile", JSON.stringify(data.data2.profile));
        localStorage.setItem("user", data.data2.id);
        localStorage.setItem("staff", data.data2.is_staff);
        navigate("/home");
        showAlert("Login successful!", "success");
      } else {
        showAlert(data.message || "Login failed, please try again!", "error");
      }
    } catch (error) {
      showAlert("An error occurred, please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50 p-4">
    
      <div className="w-full max-w-sm p-6 bg-white rounded-lg shadow-lg border border-gray-200">
      <svg>
        <circle  r={100} fill="darkblue" />
        <circle  r={90} fill="white" />
        <FaLock className="text-green text-3xl m-3 text-blue-950" />
        
      </svg>
    
        <h4 className="text-2xl font-bold mb-6 text-center text-gray-800">Login</h4>

        <div className="space-y-4">
          <div className="relative">
            <Input
              handleChange={(e) => setUsername(e.target.value)}
              value={username}
              placeholder="Your username"
              label="Username"
              className="w-full pl-10"
            />
         
          </div>
          <div className="relative">
            <Input
              handleChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder="Password"
              label="Password"
              encrpt={showPassword ? "text" : "password"}
              className="w-full pl-10"
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {isLoading ? (
            <div className="flex justify-center py-4">
              <CircularProgress />
            </div>
          ) : (
            <div className="flex w-full justify-center py-4">
              <button onClick={handleLogin} className="bg-blue-950 w-full p-3 m-2 text-white text-2xl rounded-lg">Submit</button> 
            </div>
          )}
        </div>
        <div className="mt-6 text-center">
          <button
            className="text-blue-500 hover:underline text-sm"
            onClick={() => window.open("https://www.softnixx.com/api/password_reset/")}
          >
            Forgot password?
          </button>
          <div className="mt-2">
            <button
              className="text-blue-500 hover:underline text-sm w-full"
              onClick={() => navigate("/register")}
            >
              Register!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
