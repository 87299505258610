import React, { useState, useEffect } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BarChart, Bar } from 'recharts';
import { useAlert } from '../../../alert/useAlert';
import { FaNairaSign, FaSpinner } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

import { FaCalculator, FaCalendarCheck, FaMoneyBill } from "react-icons/fa";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


const MyCharts = () => {
    const [loading, setLoading] = useState(false);
    const [lineChartData, setLineChartData] = useState([]);
    const [user, setUsers] = useState(null)
    const [totalAmount, setTotalAmount] = useState(0);
    const showMessage = useAlert();
    const [data, setData] = useState(null)
    const [data2, setData2] = useState([])
    const [amount2, setAmount2] = useState(null)
    const [amount, setAmount] = useState(null)
    const [today, setToday] = useState(null)
    const [todayBar, setTodayBar] = useState(null)
    const token = localStorage.getItem("token");
    const [selectedDate, setSelectedDate] = useState(null);
    const navigate = useNavigate()
    const [filterType, setFilterType] = useState('day'); // 'day', 'month'
 

  

    const fetchChartData = async (url) => {
        setLoading(true);
        try {
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Token ${token}`,
                },
            });
            const json = await response.json();
            return json;
        } catch (error) {
            showMessage('Something went wrong', 'error');
            
            return [];
        } finally {
            setLoading(false);
        }
    };
    const fetchLineAndBarData = async () => {
        if (!selectedDate) {
            showMessage('Please select a date.', 'error');
            return;
        }
    
        const formattedDate = filterType === 'month'
            ? selectedDate.toISOString().slice(0, 7) // Extract "YYYY-MM" for month
            : selectedDate.toISOString().split('T')[0]; // Extract "YYYY-MM-DD" for day
    
        const url = `https://www.softnixx.com/api/filter?filter=${filterType}&date=${formattedDate}`;
        const json = await fetchChartData(url);
    
       setData2(json)
    
        setAmount2(json.reduce((acc, item) => acc + item.amount, 0));
    };

    useEffect(() => {
        const getDetails = async () => {
            setLoading(true);
            try {
                const itemUrl = `https://www.softnixx.com/api/receipttoday`;
                const response = await fetch(itemUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Token ${token}`,
                    },
                });

                const json = await response.json();
                  
                const lineData = json.map(item => ({
                    name: item.name,
                    uv: item.amount,
                    pv: item.amount / 2,
                    amt: item.amount
                }));

                // Transform data for Bar Chart
                const barData = json.map(item => ({
                    name: item.name,
                    amount: item.amount
                }));

                setLineChartData(lineData);
                const total = json.reduce((acc, item) => acc + item.amount, 0);
                setTotalAmount(total);
            } catch (error) {
                setLoading(false);
                showMessage("Something went wrong", "error");
            } finally {
                setLoading(false);
            }
        };

        getDetails();
    }, [token]);


    useEffect(() => {
        const getDetails = async () => {
            setLoading(true);
            try {
                const itemUrl = `https://www.softnixx.com/api/t_oday`;
                const response = await fetch(itemUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Token ${token}`,
                    },
                });

                const json = await response.json();
                setData(json)
               
                const lineData = json.map(item => ({
                    name: item.name,
                    uv: item.amount,
                    pv: item.amount / 2,
                    amt: item.amount
                }));

                
                const barData = json.map(item => ({
                    name: item.name,
                    amount: item.amount
                }));

                setToday(lineData);
                setTodayBar(barData);
                const total = json.reduce((acc, item) => acc + item.amount, 0);
                setAmount(total);
            } catch (error) {
                setLoading(false);
                showMessage("Something went wrong", "error");
            } finally {
                setLoading(false);
            }
        };

        getDetails();
    }, [token]);


    useEffect(() => {
        const getDetails = async () => {
            setLoading(true);
            try {
                const itemUrl = `https://www.softnixx.com/api/plus`;
                const response = await fetch(itemUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Token ${token}`,
                    },
                });
                const json = await response.json();
    
                const numbers = json.amount
                const arrayTotal = numbers.reduce((acc, num) => acc + num, 0);
                setUsers(arrayTotal);
            } catch (error) {
                showMessage("Failed to fetch data", 'error');
            } finally {
                setLoading(false);
            }
        };
    
        getDetails();
    }, [token]);

    const Money =(index)=>{
        if(index){
         return index.toLocaleString()
        }

        return "0.00"
    }

    const headers = ["Type", "Amount", "Status", "Ref"];
    
    return (
        <div className="p-6 space-y-8">

<div className="flex items-center justify-center space-x-4">
    <select
        value={filterType}
        onChange={(e) => setFilterType(e.target.value)}
        className="p-2 border rounded-md"
    >
        <option value="day">Daily</option>
        <option value="month">Monthly</option>

    </select>
    <DatePicker
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        className="p-2 border rounded-md"
        dateFormat={filterType === 'month' ? "yyyy-MM" : "yyyy-MM-dd"}
        showMonthYearPicker={filterType === 'month'}
    />
    {loading?<FaSpinner className='animate-spin text-blue-500' />:
    <button
      onClick={fetchLineAndBarData}
        className="bg-blue-500 text-white px-4 py-2 rounded-md"
    >
        
        Filter
    </button>
}

</div>

              <div className="flex justify-around items-center flex-row p-3 m-3">
                    <div className=' shadow-lg rounded-lg p-3 w-64 bg-white'>
                        <center>
                        <p>Filter Type {filterType}</p>
                        { filterType  ==="month"? selectedDate?.toISOString()?.slice(0, 7) :selectedDate?.toISOString()?.split('T')[0] }
                    <p>NGN {amount2?.toLocaleString()}</p>
                    <p>{data2?.length}</p>
                        </center>
                   
                    </div>

          <div  className='p-3 m-4 shadow-lg rounded-lg bg-white'>
            <center>
                <button onClick={()=>navigate("check")} >
            <h6>All Softnixx Transactions</h6>
          <p> click here to calculate</p></button>
          </center>
          </div>
          </div>
            {/* Line Chart */}
            <div className="w-full bg-white p-4 rounded-lg shadow-md">
                <h3 className='text-xl font-semibold mb-4 text-center'>
                    Montly transactions: {lineChartData.length}
                </h3>
                <h4 className='text-lg font-medium flex flex-row items-center text-center'>
                    Monthly Spendings: <FaNairaSign className=' ml-4' />{Money(totalAmount)} 
                </h4>
                <h4 className='text-lg font-medium flex flex-row items-center text-center'>
                    Overalll User Balance: <FaNairaSign className=' ml-4' />{Money(user)} 
                </h4>
            </div>
            <div className="w-full bg-white p-4 rounded-lg shadow-md">
            <h3 className='text-xl font-semibold mb-4 text-center'>
                    Total Transactions Today: {today?.length}
                </h3>
                <ResponsiveContainer width="100%" height={400}>

                    <BarChart data={todayBar}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                        <YAxis tick={{ fontSize: 12 }} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="amount" fill='darkblue' />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div className="w-full bg-white p-4 rounded-lg shadow-md">
            <h3 className='text-xl font-semibold mb-4 text-center'>Today Transactions Details</h3>
            <table className="min-w-full table-auto">
            <thead className="bg-gray-100">
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        
                <tbody >
                    {data?.map((item, index) => (
                        <tr onClick={()=>navigate("details",{state:item?.id})} key={index} className="border-none">
                            <td className="p-2 m-1 text-sm font-light">{item.name ==="transfer"?"deposited":(item.name==="gift"?"data purchased":item?.name)}</td>
                            <td className="p-2 m-1 text-sm font-light">N{Money(item.amount)}</td>
                            <td className="p-2 m-1 text-sm font-light">{item?.type}</td>
                            <td className="p-2 m-1 text-sm font-light">{item?.reference}</td>
                        </tr>
                    ))}
                </tbody>
                <div className='flex flex-row items-center'>
                    
                    Overall: <FaNairaSign className=' ml-4 mr-1' /> {Money(amount)}
                
                </div>
            </table>
        </div>
            
        </div>
    );
};

export default MyCharts;







